import * as React from 'react';
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image";

export default function visit() {
    return(
        <Layout>
            <h1>Map Gallery</h1>
            <h2>Visit me in Val Marie</h2>
            <StaticImage className="map" width="800"
              src="../images/storefront.jpg"
              alt="A picture of a rustic building on Centre Street, Val Marie."
            />
            <p>Why pay shipping? I have a seasonal gallery space right here in Val Marie, Saskatchewan. (Getting lost in Val Marie is difficult, but it's next to the grocery store, 220 Centre Street.)</p>
            <p>Of course, you were already intending to visit nearby Grasslands National Park. Check out some exclusive poster prints, plus antiques and photographs by local artists.</p>
            <p>Hours: odd. I am generally open afternoons. Contact me ahead of time for best results.</p>
            <h2>Exclusive products</h2>
            <p>All 12”x18” prints are $24.99.</p>
            <div className="portfolio">
              <div className="pf">
                <StaticImage
                  src="../rm/Webb.jpg"
                  alt="A historic map of the Rural Municipality of Webb No. 138."
                />
                <h4>Historic Rural Municipality Maps</h4>
              </div>
              <div className="pf">
                <StaticImage
                  src="../portfolio/rural_municipalities.jpg"
                  alt="A map of Saskatchewan's rural municipalities."
                />
                <h4>Rural Municipalities of Saskatchewan</h4>
              </div>
              <div className="pf">
                <StaticImage
                  src="../cartograms/saskatchewan/Saskatchewan 2020 Election preview.png"
                  alt="A cartogram showing the results of Saskatchewan's 2020 provincial election."
                />
                <h4>Provincial Election Cartograms</h4>
              </div>
              <div className="pf">
                <StaticImage
                  src="../portfolio/hutterites.jpg"
                  alt="A map of Hutterite colonies in Saskatchewan."
                />
                <h4>Saskatchewan's Hutterite Colonies</h4>
              </div>
              <div className="pf">
                <StaticImage
                  src="../portfolio/fransaskois.jpg"
                  alt="A map of French speakers in Saskatchewan."
                />
                <h4>The Fransaskois World</h4>
              </div>
            </div>
        </Layout>
    );
}